// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; import './autoload/lazyload.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import LazyLoad from 'vanilla-lazyload';
import barba from '@barba/core';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/* global google */

/* global ajax_object */

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Scroll to anchor
   */
  $('a[href^=#]').click(function (e) {
    e.preventDefault();
    let dest = $(this).attr('href');
    let headerHeight = $('.header').outerHeight();
    $('html,body').animate(
      { scrollTop: $(dest).offset().top - headerHeight },
      700
    );
  });
  /**
   * Add subject value to table contact form
   */
  $('.table-link').click(function () {
    let form = $('#product-form-modal');
    let subject = form.find('#input_6_4');
    let value = $(this).data('subject');
    if (subject.length && value && value !== '') {
      subject.val(value);
    }
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * BarbsJS init
   */
  barba.init({
    timeout: 30000,
    transitions: [
      {
        name: 'basic',
        enter: function enter() {
          $(() => {
            // Make match height.
            $('.js-match-height').matchHeight({
              byRow: true,
              property: 'height',
              target: null,
              remove: false,
            });
          });
          /**
           * Scroll to anchor
           */
          $('a[href^=#]').click(function (e) {
            e.preventDefault();
            let dest = $(this).attr('href');
            let headerHeight = $('.header').outerHeight();
            $('html,body').animate(
              { scrollTop: $(dest).offset().top - headerHeight },
              700
            );
          });
          /**
           * Add subject value to table contact form
           */
          $('.table-link').click(function () {
            let form = $('#product-form-modal');
            let subject = form.find('#input_6_4');
            let value = $(this).data('subject');
            if (subject.length && value && value !== '') {
              subject.val(value);
            }
          });
          // Init LazyLoad (for ignore .no-lazyload).
          let lazyLoadInstance = new LazyLoad({
            elements_selector: 'img[data-lazy-src],.pre-lazyload',
            data_src: 'lazy-src',
            data_srcset: 'lazy-srcset',
            data_sizes: 'lazy-sizes',
            skip_invisible: false,
            class_loading: 'lazyloading',
            class_loaded: 'lazyloaded',
          });

          // Add tracking on adding any new nodes to body to update lazyload for the new images (AJAX for example).
          window.addEventListener(
            'LazyLoad::Initialized',
            function () {
              // Get the instance and puts it in the lazyLoadInstance variable.
              if (window.MutationObserver) {
                let observer = new MutationObserver(function (mutations) {
                  mutations.forEach(function (mutation) {
                    mutation.addedNodes.forEach(function (node) {
                      if (typeof node.getElementsByTagName !== 'function') {
                        return;
                      }
                      let imgs = node.getElementsByTagName('img');
                      if (0 === imgs.length) {
                        return;
                      }
                      lazyLoadInstance.update();
                    });
                  });
                });
                let b = document.getElementsByTagName('body')[0];
                let config = { childList: true, subtree: true };
                observer.observe(b, config);
              }
            },
            false
          );

          // Update LazyLoad images before Slide change.
          $('.slick-slider').on('beforeChange', function () {
            lazyLoadInstance.update();
          });
        },
        beforeEnter: function beforeEnter() {
          $('html, body').animate(
            {
              scrollTop: 0,
            },
            500
          );

          if (!$('.js-navbar-toggler').hasClass('collapsed')) {
            $('.js-navbar-toggler').trigger('click');
          }
        },
        after: function after() {
          let pageEdit = $(document).find('#bwp-main').data('edit');
          let formWrap = $(document).find('.form-wrapper');
          if (pageEdit) {
            $(document).find('#wp-admin-bar-edit > a').attr('href', pageEdit);
          }
          if (formWrap.length) {
            let formID = formWrap.attr('data-form-id');

            $.ajax({
              url: ajax_object.ajax_url,
              type: 'GET',
              data: {
                form_id: formID,
                action: 'get_form',
              },
            }).done(function (response) {
              $('.form-wrapper').html(response);

              setTimeout(function () {
                formWrap.find('script').each((index, el) => {
                  window.eval($(el).text());
                });
              }, 1500);
            });
          }

          // Override bootstrap submenu toggle
          $('a.dropdown-toggle').on('click keyup', function (e) {
            e.preventDefault();
            e.stopPropagation();
            let $this = $(e.target);
            bootstrapSubmenuToggle($this);
          });

          // Render gMaps
          $('.acf-map').each(function () {
            render_map($(this));
          });
        },
      },
    ],
    prevent: ({ el }) =>
      el.classList &&
      (el.classList.contains('ab-item') ||
        el.classList.contains('fancybox-image')),
  });

  // Menu toggle active class x-icon
  $(document).on('click', '.js-navbar-toggler', function () {
    $(this).toggleClass('is-active');
  });

  // Override bootstrap submenu toggle
  $('a.dropdown-toggle').on('click keyup', function (e) {
    e.preventDefault();
    e.stopPropagation();
    let $this = $(e.target);

    bootstrapSubmenuToggle($this);
  });

  function bootstrapSubmenuToggle($toggle) {
    if ($toggle === undefined || !$toggle.length) return false;

    if ($toggle.hasClass('js-dropdown-toggle')) {
      $toggle
        .closest('.dropdown')
        .toggleClass('show')
        .find('.dropdown-menu')
        .toggleClass('show');
      $toggle
        .toggleClass('is-active')
        .closest('.dropdown-toggle')
        .attr('aria-expanded', (i, attr) =>
          attr === 'true' ? 'false' : 'true'
        );
    } else if ($toggle.closest('a').attr('href')) {
      barba.go($toggle.closest('a').attr('href'));
    }
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  $('.acf-map').each(function () {
    render_map($(this));
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('html')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop);
  if (st === 0) {
    $('html').removeClass('scrolling-top scrolling-bottom');
  }
  lastScrollTop = st;
});

// ACF Google Map JS code

/*
 *  This function will render a Google Map onto the selected jQuery element
 */

function render_map($el) {
  // var
  var $markers = $el.find('.marker');
  // var styles = []; // Uncomment for map styling

  // vars
  var args = {
    zoom: 14,
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    styles: [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#193341' }],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ color: '#2c5a71' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#29768a' }, { lightness: -37 }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#406d80' }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#406d80' }],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          { visibility: 'on' },
          { color: '#3e606f' },
          { weight: 2 },
          { gamma: 0.84 },
        ],
      },
      { elementType: 'labels.text.fill', stylers: [{ color: '#ffffff' }] },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{ weight: 0.6 }, { color: '#1a3541' }],
      },
      { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#2c5a71' }],
      },
    ], // Uncomment for map styling
  };

  // create map
  var map = new google.maps.Map($el[0], args);

  // add a markers reference
  map.markers = [];

  // add markers
  $markers.each(function () {
    add_marker($(this), map);
  });

  // center map
  center_map(map);
}

/*
 *  This function will add a marker to the selected Google Map
 */

var infowindow;

function add_marker($marker, map) {
  // var
  var latlng = new google.maps.LatLng(
    $marker.attr('data-lat'),
    $marker.attr('data-lng')
  );

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    icon: $marker.data('marker-icon'), //uncomment if you use custom marker
  });

  // add to array
  map.markers.push(marker);

  // if marker contains HTML, add it to an infoWindow
  if ($.trim($marker.html())) {
    // create info window
    infowindow = new google.maps.InfoWindow();

    // show info window when marker is clicked
    google.maps.event.addListener(marker, 'click', function () {
      // Close previously opened infowindow, fill with new content and open it
      infowindow.close();
      infowindow.setContent($marker.html());
      infowindow.open(map, marker);
    });
  }
}

/*
 *  This function will center the map, showing all markers attached to this map
 */

function center_map(map) {
  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each(map.markers, function (i, marker) {
    var latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length == 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}
